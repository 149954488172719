import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SyncOutlined } from '@ant-design/icons';

import viveBgLoader from 'src/assets/images/logo/vive-loader-bg.png';

const LoaderComponent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 6000;
  background-color: rgba(0,0,0,.50);
  top: 0;
  right: 0;
  
  &.blank{
    background-color: rgba(0,0,0,.2);
  }

  /*@media only screen and (min-width: 768px) and (max-width: 1220px) {
    width: calc(100% - 80px);
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }*/

  .viveContentLoader {
    width: 60px;
    height: 60px;
    background-image: url(${viveBgLoader});
    background-repeat: no-repeat;
    background-size: contain;
    animation: svgSpinner 1.4s linear infinite;
  }


  @keyframes svgSpinner {
    100% {
      transform: rotate(360deg);
    }
  }
  
`;

const SyncSpin = (props) => (<SyncOutlined className={`${props.className} vive-link-gold`} spin style={{fontSize: 20}}/>);

export {SyncSpin}

export default function Loader(props) {
  const {type} = props

  function preventDefault(evt) {
    evt.stopPropagation();
  }

  return (
    <LoaderComponent className={type} onClick={preventDefault}>
    { type==='vive-spin' && (
      <div className="viveContentLoader"></div>
    )}
    </LoaderComponent>
  );
};

Loader.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
};

Loader.defaultProps = {
  onClick: () => {},
  type: 'vive-spin',  // vive-spin || blank
};
