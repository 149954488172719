import { createGlobalStyle } from 'styled-components';
import 'antd/dist/antd.css';
import 'react-phone-input-2/lib/style.css';
import Manrope from 'src/assets/fonts/Manrope.ttf';
import PlayFair from 'src/assets/fonts/PlayfairDisplay.ttf';

import {
  themeBgColor,
  txtPriColor,
  secColor,
  txtSecColor,
  darkLineColor2,
  txtBgColor,
  txtTriColor,
  txtDisabled1,
  hrLineColor,
  triColor,
  txtPlaceholderColor,
  txtBgColorDark,
  errorColor,
  successColor,
  borderRadiusSm,
  darkGray1
} from 'src/style/GlobalVars';
import { borderRadiusMd, txtPriDarker } from './GlobalVars'

const GlobalStyles = createGlobalStyle`
  /*@import url('https://fonts.googleapis.com/css2?family=Sen:wght@400;700&display=swap');*/
  @font-face {
    font-family: Manrope;
    src: url(${Manrope}) format('truetype');
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: PlayFair;
    src: url(${PlayFair}) format('truetype');
    font-style: normal;
    font-display: auto;
  }
  
  body{
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
  }
  
  /***********************/
  /** vive Global ************/
  /***********************/
  
  body#vive{
    
    #root{
      height: 100vh;
      background-color: ${themeBgColor};
    }

    h1{
      font-family: PlayFair;
    }
    
    p,
    input,
    button,
    textarea{
      font-size: 16px;
    }

    .vive-hidden{
      display: none !important;
    }

    .vive-disabled{
      pointer-events: none;
    }

    .vive-visibility-hidden{
      visibility: hidden !important;
    }
      
    .vive-card{
      padding: 50px 88px 34px;
      border-radius: 10px;
      background-color: #292929;
      
      .txt-body{
        color: ${txtPriColor};
        margin-bottom: 8px;
      }
      
      p{
        color: ${txtPriColor};
      }
    }
    
    .d-flex {
      display: flex;

      &-v-align-center {
        align-items: center;
      }
    }

    .card-title{
      color: ${txtPriColor};
      font-size: 30px;
      text-align: center;
      font-weight: bold;
      margin-bottom: 8px;
    }
    
    .card-sub-title{
      color: ${txtPriColor};
      font-size: 16px;
      text-align: center;
      margin-bottom: 12px;
    }
    
    .card-body{
      width: 100%;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      padding: 26px 24px 20px;
    }
    
    .vive-btn01{
      padding: 20px 36px;
      border: none;
      color: ${txtTriColor}!important;
      border-radius: 8px;
      background: linear-gradient(228deg, #CCAB69 0%, #F4DA9A 100%);
      line-height: normal;
      height: auto;
      font-weight: bold;
      cursor: pointer;
      
      &.wide-sm{
        padding: 16px 40px;
        font-size: 12px;
      }

      &.rounded-sm {
          padding: 6px 10px;
          font-size: 12px;
          border-radius: 50px;
      }
      
      &.sm{
        border-width: 1px;
        padding: 6px 10px;
        font-size: 12px;
        border-radius: 3px;
      }
    }
    
    .vive-btn01-outlined{
      padding: 26px 36px;
      background: none;
      border: 2px solid #F4DA9A;
      color: #F4DA9A;
      border-radius: 8px;
      line-height: normal;
      height: auto;
      font-weight: bold;
      cursor: pointer;
      &:hover{
        color: #f7e9c8;
        border: 2px solid #f7e9c8;
      }
      &.md{
        padding: 12px 26px;
        font-size: 14px;
        border-width: 1px
      }
      &.sm{
        border-width: 1px;
        padding: 6px 10px;
        font-size: 12px;
        border-radius: 3px;
      }
      &.grayed{
        border-color: ${txtPlaceholderColor};
        color: ${txtPlaceholderColor};
      }
      &:disabled {
        border-color: ${txtDisabled1};
        color: ${txtDisabled1};
        pointer-events: none;
      }
    }
    
    .vive-btn01-rounded-outlined{
      padding: 20px 36px;
      background: none;
      border: 2px solid #cbaa68;
      color: #cbaa68;
      border-radius: 10px;
      line-height: normal;
      height: auto;
      font-weight: bold;
      cursor: pointer;
      
      &:hover{
        color: #cbaa68;
        border: 2px solid #cbaa68;
      }
      
      &.sm{
        border: 1px solid #cbaa68;
        padding: 6px 10px;
        font-size: 12px;
        border-radius: 50px;
      }

      &.sm-br-04{
        border: 1px solid #cbaa68;
        padding: 6px 10px;
        font-size: 12px;
        border-radius: 4px;
      }
      
      &.grayed{
        border: 2px solid ${txtPlaceholderColor};
        color: ${txtPlaceholderColor};
      }
    }
    
    .vive-btn-less{
      border: none;
      background: none;
      padding: 0;
      color: ${txtPriColor};
      cursor: pointer;
      &:disabled {
        border-color: ${txtDisabled1};
        color: ${txtDisabled1};
        pointer-events: none;
      }
    }
    
    .control-btn{
      color: ${txtPlaceholderColor}!important;
      font-size: 14px!important;
      &:hover{
        color: ${txtPriColor}!important;
      }
    }
    
    .vive-radio-group{
      >*{
        padding: 24px 12px;
        border: none;
        color: ${txtPriColor}!important;
        background-color: ${triColor};
        line-height: normal;
        height: auto;
        text-align: center;
        
        &:first-child{
          border-radius: 5px 0 0 5px;
        }
        &:last-child{
          border-radius: 0 5px 5px 0;
        }
        &:before{
          background-color: ${darkLineColor2};
        }
         
        &.ant-radio-button-wrapper-checked{
          background: #F4DA9A;
          color: ${txtTriColor}!important;
        }
      }
    }
    
    .vive-link-gold{ 
      color: ${txtSecColor};  
      text-decoration: none;
    }
    
    .card-hr{
      width: 100%;
      border: 1px solid ${hrLineColor};
    }
    
    .service-status{
      text-transform: uppercase;
      padding: 6px 12px;
      color: ${txtPriColor};
      border-radius:${borderRadiusSm};
      display: inline-block;
      text-align: center;
      
      &.completed{
        background: ${successColor};
      }
      
      &.pending{
        background: ${secColor};
        color: ${darkGray1};
      }
      
      &.cancelled{
        background-color: ${errorColor};
      }
      &.noshow{
        background-color: ${errorColor};
      }
      &.capitalize{
        text-transform: capitalize;
      }
      &.sm{
        padding: 2px 10px;
      }
    }
    
    element {
      padding: 3px 10px;
      border-radius: 3px;
      background: #1d9c17;
      color: #abffa9;
      text-transform: capitalize;
    }
    
    .notice{
      color: ${txtPriColor};
      background-color: ${errorColor};
      padding: 4px 6px;
      border-radius: ${borderRadiusSm};
    }

    .error-container{
      border: solid;
      border-color: ${errorColor};
      border-radius: ${borderRadiusSm};
      border-width: 1px;
    }

    .ant-rate {
      color: ${txtSecColor}!important;
    }
    
    /***********************/
    /**Public Content Box***/
    /***********************/
    
    .public-container{
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 30px;
      padding-right: 30px;
      width: 100%;
      flex: 1;
    }
    
    
    /***********************/
    /**Display Size*********/
    /***********************/
    .w100{ width: 100% }
    
    /***********************/
    /**Text**********/
    /***********************/
    .txt-pri{ color: ${txtPriColor}; }
    .txt-sec{ color: ${txtSecColor}; }
    .txt-tri{ color: ${txtTriColor}; }
    .txt-pri-darker{ color: ${txtPriDarker}; }
    .txt-placeholder{ color: ${txtPlaceholderColor}; }
    
    .txt-line-clamp-2{
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: normal;  
    }
    
    .txt-line-clamp-3{
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: normal;  
    }
 
    .txt-sm{
      font-size: 14px;
    }
    .txt-sm-bold {
      font-size: 14px;
      font-weight: bold;
    }
    .txt-sm-grayed{
      font-size: 14px;
      color: ${txtPriDarker};
    }
    
    .txt-md{
      font-size: 16px;
    }
    
    .txt-md-bold {
      font-size: 16px;
      font-weight: bold;
    }

    .txt-md-grayed{
      font-size: 16px;
      color: ${txtPriDarker};
    }
    
    .txt-lg{
      font-size: 18px;
    }

    .txt-lg-bold{
      font-size: 18px;
      font-weight: bold;
    }

    .truncate{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    
    /***********************/
    /**Alignment************/
    /***********************/
    .txt-alg-left{ text-align: left }
    .txt-alg-center{ text-align: center }
    .txt-alg-right{ text-align: right }
    .justify-center{ justify-content: center; } 
    .justify-right{ justify-content: center; } 
    .align-self-center{  align-self: center; }
    
    /***********************/
    /**Spacing**************/
    /***********************/
    .mt-xxs{ margin-top: 3px; }
    .mt-xs{ margin-top: 6px; }
    .mt-sm{ margin-top: 12px; }
    .mt-md{ margin-top: 18px; }
    .mt-lg{ margin-top: 26px; }
    .mt-xl{ margin-top: 32px; }
    .mt-xl2{ margin-top: 46px; }
    .mt-xl3{ margin-top: 60px; }
    
    .mb-xs{ margin-bottom: 6px; }
    .mb-sm{ margin-bottom: 12px; }
    .mb-md{ margin-bottom: 18px; }
    .mb-lg{ margin-bottom: 26px; }
    .mb-xl{ margin-bottom: 32px; }
    .mb-xl2{ margin-bottom: 46px; }
    .mb-xl3{ margin-bottom: 60px; }
    
    .ml-auto{ margin-left: auto; }
    .ml-xs{ margin-left: 6px; }
    .ml-sm{ margin-left: 12px; }
    .ml-md{ margin-left: 18px; }
    .ml-lg{ margin-left: 26px; }
    .ml-xl2{ margin-left: 46px; }
    .ml-xl3{ margin-left: 60px; }
    
    .mr-auto{ margin-right: auto; }
    .mr-xs{ margin-right: 6px; }
    .mr-sm{ margin-right: 12px; }
    .mr-md{ margin-right: 18px; }
    .mr-lg{ margin-right: 26px; }
    
    .m-auto{ margin: auto; }
    .plr-sm{ padding-left: 6px; padding-right: 6px; }
    .ptb-sm{ padding-top: 6px; padding-bottom: 6px; }
    .plr-md{ padding-left: 12px; padding-right: 12px; }
    .ptb-md{ padding-top: 12px; padding-bottom: 12px; }
    
    /***********************/
    /**Border radius********/
    /***********************/
    
    br-sm{ border-radius: 4px}
    br-md{ border-radius: 8px}
    
    /***********************/
    /**Flex Layout**********/
    /***********************/
    
    .flex-row{
      display: flex;
      &.hgap-sm >*:not(:last-child){
        margin-right: 12px;
      }
      &.hgap-md >*:not(:last-child){
        margin-right: 18px;
      }
      &.hgap-lg >*:not(:last-child){
        margin-right: 24px;
      }
    }
    .flex-row-wrap{
      display: flex;
      flex-wrap: wrap;
      &.hgap-sm >*:not(:last-child){
        margin-right: 12px;
      }
      &.hgap-lg >*:not(:last-child){
        margin-right: 24px;
      }
    }
    .flex-column{
      display: flex;
      flex-flow: column;
    }
    
    .flex-row_cell-auto{
      display: flex;
      >*{
        flex: 1 1 auto;
      }
      &.hgap-sm >*:not(:last-child){
        margin-right: 12px;
      }
      &.hgap-md >*:not(:last-child){
        margin-right: 18px;
      }
      &.hgap-xl >*:not(:last-child){
        margin-right: 32px;
      }
      &.hgap-xl2 >*:not(:last-child){
        margin-right: 46px;
      }
    }
    
    .flex-row_cell-uniform{
      display: flex;
      >*{
        flex-grow: 1;
        flex-basis: 0;
      }
      &.hgap-sm >*:not(:last-child){
        margin-right: 12px;
      }
      &.hgap-md >*:not(:last-child){
        margin-right: 18px;
      }
      &.hgap-lg >*:not(:last-child){
        margin-right: 26px;
      }
      &.hgap-xl >*:not(:last-child){
        margin-right: 32px;
      }
      &.hgap-xl2 >*:not(:last-child){
        margin-right: 46px;
      }
    }
    .flex-grid{
      display: flex;
      flex-wrap: wrap;
      >.cell_1-2{
        width: calc(50%);
        max-width: calc(50%);
      }
      >.cell_1-3{
        width: calc(33.333333333%);
        max-width: calc(33.333333333%);
      }
      >.cell_1-5{
        width: calc(20%);
        max-width: calc(20%);
      }
      >.cell_2-3{
        width: calc(66.666666666%);
        max-width: calc(66.666666666%);
      }
      >.cell_1-4{
        width: calc(25%);
        max-width: calc(25%);
      }
      &.hgap-sm{
        margin-left: -6px;
        margin-right: -6px;
        >*{
          padding-left: 6px;
          padding-right: 6px;
        }
      }
      &.hgap-md{
        margin-left: -12px;
        margin-right: -12px;
        >*{
          padding-left: 12px;
          padding-right: 12px;
        }
      }
    }
 
    .gal-item {
      display: flex;
      border-radius: ${borderRadiusSm};
      background-color: #242525;
      height: 150px;
      overflow: hidden;

      &.gal-item-lr {
        height: 400px;
      }

      img {
        width: 100%;
        height: 100%;
        margin: auto;
        object-fit: cover;
      }
    }
  

    .justify-center{
      justify-content: center;
    }
    .align-center{
      align-items: center;
    }
    .align-start{
      align-items: flex-start;
    }
    .cell-grow{
      flex: 1;
    }
    
    /***********************/
    /** Media Query ********/
    /***********************/
     //Desktops, large screens
    @media (max-width: 1200px) { 
      .flex-drop1200{
        flex-direction: column; 
        >*{
          margin-right: 0!important;
          margin-left: 0!important;
          flex-basis: auto;
        }
        >.drop-mt-xs{ margin-top: 6px; }
        >.drop-mt-sm{ margin-top: 12px; }
        >.drop-mt-md{ margin-top: 18px; }
        >.drop-mt-lg{ margin-top: 26px; }
      }
      .hidden1200{
        display:none!important;
      }
    }
    //Small screens, laptops
    @media (max-width: 1024px) { 
      .flex-drop1024{
        flex-direction: column; 
        >*{
          margin-right: 0!important;
          margin-left: 0!important;
          flex-basis: auto;
        }
        >.drop-mt-xs{ margin-top: 6px; }
        >.drop-mt-sm{ margin-top: 12px; }
        >.drop-mt-md{ margin-top: 18px; }
        >.drop-mt-lg{ margin-top: 26px; }
      }
      .hidden1024{
        display:none!important;
      }
    }
    //iPads, Tablets
    @media (max-width: 768px) { 
      .flex-drop768{
        flex-direction: column; 
        >*{
          margin-right: 0!important;
          margin-left: 0!important;
          flex-basis: auto;
        }
        >*{
          min-width: 100%;
        }
        >.drop-mt-xs{ margin-top: 6px; }
        >.drop-mt-sm{ margin-top: 12px; }
        >.drop-mt-md{ margin-top: 18px; }
        >.drop-mt-lg{ margin-top: 26px; }
      }
      .hidden768{
        display:none!important;
      }
    }
    //Mobile devices
    @media (max-width: 480px) { 
      .flex-drop480{
        flex-direction: column;
        >*{
          margin-left: 0!important;
          margin-right: 0!important;
          flex-basis: auto;
        }
        >*{
          min-width: 100%;
        }
        >.drop-mt-xs{ margin-top: 6px; }
        >.drop-mt-sm{ margin-top: 12px; }
        >.drop-mt-md{ margin-top: 18px; }
        >.drop-mt-lg{ margin-top: 26px; }
      }
      .hidden480{
        display:none!important;
      }
      .public-container{
        padding-left: 18px;
        padding-right: 18px;
      }
    }
    
  }

  /***********************/
  /** Plugins ************/
  /***********************/
  body{
    .phone-wrapper.required input{
      border: 1px solid ${errorColor}!important;
    }
    
    .phone-wrapper.darker-bg{
      input{
        background-color: ${txtBgColorDark}!important;
      }
      .flag-dropdown.open{
        background-color: ${txtBgColorDark}!important;
      }
    }
    
    .react-tel-input{
      input{ width: 100% !important; }
      
      .selected-flag{
        background: none!important;
      }
      
      .flag{
        margin-top: -6px;
        .arrow{
          border:none;
          &:after{
            border-color: ${txtPriColor};
            border-bottom-style: solid;
            border-bottom-width: 1px;
            border-right-style: solid;
            border-right-width: 1px;
            content: '';
            display: inline-block;
            height: 6px;
            left: 3px;
            position: absolute;
            top: -2px;
            width: 6px;
            transform: rotate(45deg);
          }
        }
      }
      
      .flag-dropdown{
        border-radius: ${borderRadiusSm} 0 0 ${borderRadiusSm};
        background: none;
        border: none;
        padding: 0 16px;
        
        .country-name{
          color: ${txtTriColor}
        }
        
        &.open {
          background: ${txtBgColor};
          border-radius: ${borderRadiusSm} 0 0 ${borderRadiusSm};
        }
        
        &:after{
          content: "";
          position: absolute;
          right: 0;
          top: 12px;
          bottom: 12px;
          background: ${txtBgColor};
          width: 1px;
        }
      }
      input{
        flex: 1 1 auto;
        position: relative;
        width: 100%;
        padding-left: 78px!important;
      }
    }
    
    .scrollbar-track-y,
    .scrollbar-thumb-y {
      width: 5px !important;
    }
    
    .scrollbar-track-x,
    .scrollbar-thumb-x {
      height: 5px !important;
    }
    
    .scrollbar-thumb {
      border-radius: 0 !important;
    }
    
    .scrollbar-track {
      background: rgba(222, 222, 222, 0.15) !important;
    }
    
    .scrollbar-thumb {
      border-radius: 0 !important;
      background: rgba(0, 0, 0, 0.5) !important;
    }
    
    .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
    .ant-popover-placement-bottomLeft
      > .ant-popover-content
      > .ant-popover-arrow:after,
    .ant-popover-placement-bottomRight
      > .ant-popover-content
      > .ant-popover-arrow:after,
    .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
    .ant-popover-placement-topLeft
      > .ant-popover-content
      > .ant-popover-arrow:after,
    .ant-popover-placement-topRight
      > .ant-popover-content
      > .ant-popover-arrow:after {
      left: 0;
      margin-left: -4px;
    }
    .ant-image-preview-mask{
      background-color: rgba(0,0,0,.90)
    }
    .ant-image-preview-operations{
      background-color: rgba(0,0,0,.90)
    }
  }
    
  /***********************/
  /** Global ************/
  /***********************/
  body {
    -webkit-overflow-scrolling: touch;
  }
  
  html h1,
  html h2,
  html h3,
  html h4,
  html h5,
  html h6,
  html a,
  html p,
  html li,
  input,
  textarea,
  span,
  div,
  html,
  body,
  html a {
    margin-bottom: 0;
    font-family: Manrope;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: none;
  }
  
  body { margin: 0; }
  
  html ul {
    -webkit-padding-start: 0px;
    list-style: none;
    margin-bottom: 0;
  }
`;

export default GlobalStyles;
