import React, { useState, useEffect } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { Provider } from 'react-redux';

import { useAppApolloClient } from 'src/services/apollo/apolloClient';
import { setAccessToken, setDetectedCountry } from 'src/services/apollo/auth';
import { store } from 'src/services/redux/store';
import AppRoutes from './AppRoutes';
import GlobalStyles from 'src/style/GlobalStyle';
import Loader from 'src/components/Loader';
import config from 'src/config/config';

function App() {
  const apolloClient = useAppApolloClient();

  const [loading, setLoading] = useState(<Loader />);

  /*useEffect(() => {
    fetch('https://ip2c.org/?self')
      .then(response => response.text())
      .then(async data => {
        setDetectedCountry(data.split(";")[1].toLowerCase());
      });
  }, []);*/

  useEffect(() => {
    fetch(config.API_URL+"/refresh_token", {
      method: "POST",
      credentials: "include"
    }).then(async token => {
      const { accessToken } = await token.json();
      setAccessToken(accessToken);
      setLoading( <AppRoutes />);
    });
  }, []);

  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <React.Fragment>
          <GlobalStyles />
          {loading}
        </React.Fragment>
      </ApolloProvider>
    </Provider>
  );
}

export default App;
