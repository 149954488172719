export const themeBgColor = '#313232';

export const errorColor = '#b40203';
export const successColor = '#1d9c17';

export const priColor = '#fff';
export const secColor = '#cbaa68';
export const triColor = '#313232';

export const txtPriColor = '#fff';
export const txtSecColor = '#cbaa68';
export const txtTriColor = '#313232';
export const txtPriDarker = '#959595';
export const txtDisabled1 = '#464646';
export const txtDisabled2 = '#626262';
export const txtPlaceholderColor = '#616161';
export const txtBgColor = '#333';
export const txtBgColorDark = '#292929';
export const txtPriDbColor = '#636363';

export const priFontSize = '16px';
export const secFontSize = '14px';

export const darkLineColor1 = '#393939';
export const darkLineColor2 = '#444';
export const liteLineColor1 = '#e7e7e7';
export const hrLineColor = '#393939';
export const hrLineLight = '#616161';

export const darkGray1 = '#1c1c1c';
export const darkGray2 = '#292929';
export const darkGray3 = '#313232';
export const darkGray4 = '#404040';
export const darkGray5 = '#5b5b5b';

export const topBarPanelTxtColor = '#666';
export const topBarPanelBg = '#1e1e1e';
export const topBarPanelBorder = '#929292';
export const topBarPanelHoverBg = '#0d0d0d';

export const borderRadiusXs = '3px';
export const borderRadiusSm = '4px';
export const borderRadiusMd = '8px';
export const borderRadiusLg = '10px';

export const txtXs = '12px';
export const txtSm = '14px';
export const txtMd = '16px';
export const txtLg = '18px';
export const marginSm = '12px';
export const marginMd = '18px';
export const marginLg = '24px';
export const marginXl = '32px';
export const marginXl2 = '46px';
export const plrSm = '12px';
export const plrMd = '18px';
export const ptbXs = '6px';
export const ptbSm = '12px';
export const ptbLg = '18px';
export const ptbXl = '24px';

export const popBorder = 'solid 1px #929292'
export const liteHoverShadow = '0px 5px 20px rgba(0,0,0,.2)'

