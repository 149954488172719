import React, { lazy, Suspense } from 'react';
import { Route, Redirect, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';

import { getAccessToken } from "src/services/apollo/auth";
import Loader from 'src/components/Loader';

const Public = lazy(() => import('src/containers/Public'));
const Private = lazy(() => import('src/containers/Private'));

function PrivateRoute({ children, ...rest }) {
  const location = useLocation();
  const isLoggedIn = getAccessToken();
  if (isLoggedIn) return <Route {...rest}>{children}</Route>;
  return (
    <Redirect
      to={{
      pathname: '/',
      search: '?signin=true',
      state: { from: location }}}
    />
  );
}

export default function AppRoutes() {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Switch>
          <PrivateRoute path="/dashboard">
            <Private />
          </PrivateRoute>
          <Public />
        </Switch>
      </Router>
    </Suspense>
  );
}
