import { useApolloClient } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import jwtDecode from "jwt-decode";
import { store } from 'src/services/redux/store'


let accessToken = "";
let userInfo;
let profilePhoto;
let detectedCountry = "ph";

//const { fetchProfileDataSuccess } = profileActions;

export const setAccessToken = (token) => {
  accessToken = token;
  if(accessToken && accessToken != "")
    setUserInfo(jwtDecode(token));
};

export const getAccessToken = () => {
  return accessToken;
};

export const removeAccessToken = () => {
  accessToken = '';
  return true;
};

export const setUserInfo = (_userInfo) => {
  userInfo = _userInfo;
  if(_userInfo && _userInfo.profile_photo){
    setProfilePhoto(_userInfo.profile_photo);
  }
};

export const getUserInfo = () => {
  return userInfo;
};

export const setProfilePhoto = (_profilePhoto) => {
  profilePhoto = _profilePhoto;
};

export const getProfilePhoto = () => {
  return profilePhoto;
};

export const setDetectedCountry = (_country) => {
  detectedCountry = _country;
};

export const getDetectedCountry  = () => {
  return detectedCountry;
};

export const useAuth = () => {
  const setAuthToken = (authToken) => {
    //let objUserInfo = jwtDecode(authToken);
    setAccessToken(authToken);
    //store.dispatch(fetchProfileDataSuccess(objUserInfo));
  }
  return [setAuthToken];
};

export const useLogout = () => {
  const history = useHistory();
  const apolloClient = useApolloClient();

  const logout = async () => {
    await apolloClient.clearStore();
    removeAccessToken();
    history.push('/');
  };
  return logout;
};
