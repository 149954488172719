import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink, Observable } from "apollo-link";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { getAccessToken, setAccessToken } from "./auth";
import jwtDecode from "jwt-decode";
import { onError } from "apollo-link-error";
import config from "src/config/config";
import { HttpLink } from "apollo-link-http";
//import { getMainDefinition } from "@apollo/client/utilities";
//import { WebSocketLink } from '@apollo/client/link/ws';
//import { split } from '@apollo/client';

const uriLink = config.API_URL;
//const wsUriLink = config.WS_API_URL;

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(operation => {
          const accessToken = getAccessToken();
          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `bearer ${accessToken}`
              }
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const httpLink = new HttpLink({
  uri: uriLink + "/graphql",
  credentials: "include"
});

/*const wsLink = new WebSocketLink({
  uri: wsUriLink + "/graphql",
  options: {
    reconnect: true
  }
});

const currentLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  //wsLink,
  httpLink
);*/

const apolloLinkData = ApolloLink.from([
  new TokenRefreshLink({
    accessTokenField: "accessToken",
    isTokenValidOrUndefined: () => {
      const token = getAccessToken();

      if (!token) {
        return true;
      }

      try {
        const { exp } = jwtDecode(token);
        if (Date.now() >= exp * 1000) {
          return false;
        } else {
          return true;
        }
      } catch {
        return false;
      }
    },
    fetchAccessToken: () => {
      return fetch(uriLink + "/refresh_token", {
        method: "POST",
        credentials: "include"
      });
    },
    handleFetch: accessToken => {
      setAccessToken(accessToken);
    },
    handleError: err => {
      console.warn("Your refresh token is invalid. Try to relogin");
      console.error(err);
    }
  }),
  onError(({ graphQLErrors, networkError }) => {
    console.log('graphQLErrors', graphQLErrors)
    console.log('networkError', networkError)
  }),
  requestLink,
  httpLink
]);

export const useAppApolloClient = () => {
  return new ApolloClient({
    link: apolloLinkData,
    cache: new InMemoryCache()
  });
};
